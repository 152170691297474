import React, { useEffect, useState } from 'react'; 
import { Layout, Breadcrumb } from 'antd';
import { HashRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import MenuSidebar from './components/Menu'; 
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { checkAuthStatus } from './api';

const { Header, Content, Sider } = Layout;

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(true); // Thêm loading state cho quá trình xác thực
  const [collapsed, setCollapsed] = useState(false); 
  const navigate = useNavigate(); 

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token'); 

      if (token) {
        try {
          const response = await checkAuthStatus(token); 
          if (response.data.status === 200) {
            setIsLoggedIn(true); 
          } else {
            setIsLoggedIn(false);
            localStorage.removeItem('token'); 
            navigate('/login'); 
          }
        } catch (error) {
          console.error('Error checking auth status:', error);
          setIsLoggedIn(false);
          navigate('/login');
        }
      } else {
        setIsLoggedIn(false); 
        navigate('/login');
      }
      setLoadingAuth(false); // Hoàn tất quá trình kiểm tra, tắt loading
    };

    checkAuth(); 
  }, [navigate]);

  if (loadingAuth) {
    // Nếu đang trong quá trình xác thực thì hiển thị trang loading
    return <div>Đang xác thực, vui lòng đợi...</div>;
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {isLoggedIn && (
        <Sider theme='light' width={200} className="site-layout-background" collapsible collapsed={collapsed} onCollapse={setCollapsed}>
          <MenuSidebar hasChild={true} setIsLoggedIn={setIsLoggedIn} /> 
        </Sider>
      )}
      <Layout>
        {isLoggedIn && (
          <Header className="site-layout-background" style={{ padding: 0, background: '#001529' }}>
            <h2 style={{ color: 'white', margin: '0 20px' }}>Admin Dashboard</h2>
            <span style={{ float: 'right', margin: '0 20px', color: 'white', cursor: 'pointer' }} onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </span>
          </Header>
        )}
        <Content style={{ margin: '16px', background: '#f0f2f5' }}>
          {isLoggedIn ? (
            <>
              <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              </Breadcrumb>
              <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </div>
            </>
          ) : (
            <Routes>
              <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          )}
        </Content>
      </Layout>
    </Layout>
  );
}

export default App;
