import React from 'react';
const Home = ({ hasChild }) => {
    return (
        
                    <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
                        <h3>Welcome to the Admin Dashboard!</h3>
                        <p>Here you can manage users, settings, and more.</p>
                    </div>
    );
};

export default Home;
