import axios from 'axios'; // Nhập axios

const host = 'https://lotilife.com/wp-json'; // Tạo biến host

export const login = async (values) => {
    try {
        const response = await axios.post(`${host}/my-affiliate/v1/login`, values, {
            headers: {
                'Content-Type': 'application/json',
            },            
        });
        return response.data; // Trả về dữ liệu từ phản hồi
    } catch (error) {
        console.error('Login error:', error);
        throw new Error(error.response?.data?.message || 'Login failed'); // Trả về thông báo lỗi
    }
};

export const logout = async () => {
    try {
        const response = await axios.post(`${host}/my-affiliate/v1/logout`, {}, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true // Gửi cookie kèm theo yêu cầu
        });
        return response.data; // Trả về dữ liệu từ phản hồi
    } catch (error) {
        console.error('Logout error:', error);
        throw new Error(error.response?.data?.message || 'Logout failed'); // Trả về thông báo lỗi
    }
};
export const checkAuthStatus = async (token) => {
    try {
        const response = await axios.post(`${host}/jwt-auth/v1/token/validate`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data; // Trả về dữ liệu phản hồi từ API
    } catch (error) {
        console.error('Auth status check failed:', error);
        throw error;
    }
};
