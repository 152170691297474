import React, { useState } from 'react';
import { Menu, Button, Spin, message } from 'antd';
import { DashboardOutlined, LogoutOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../api'; // Hàm logout từ API

const MenuSidebar = ({ hasChild, setIsLoggedIn }) => {
    const location = useLocation();
    const navigate = useNavigate(); // Thêm useNavigate để điều hướng
    const [loading, setLoading] = useState(false); // Trạng thái loading

    const handleLogout = async () => {
        setLoading(true); // Bắt đầu loading
        try {
            const response = await logout();
            if (response.success) {
                message.success(response.message);

                // Xóa tất cả thông tin đăng nhập khỏi localStorage
                localStorage.removeItem('token');
                localStorage.removeItem('user_id');
                localStorage.removeItem('user_login');
                localStorage.removeItem('user_email');

                // Cập nhật trạng thái đăng nhập
                setIsLoggedIn(false); 

                // Sử dụng navigate để điều hướng về trang login thay vì reload trang
                navigate('/login', { replace: true });
            } else {
                message.error('Lỗi khi đăng xuất. Vui lòng thử lại.');
            }
        } catch (error) {
            console.error('Logout error:', error);
            message.error(`Có lỗi xảy ra: ${error.message}`);
        } finally {
            setLoading(false); // Kết thúc loading
        }
    };

    return (
        <Menu theme="light" selectedKeys={[location.pathname]} mode="inline">
            <Menu.Item key="/" icon={<DashboardOutlined />}>
                <Link to="/">Dashboard</Link>
            </Menu.Item>            
            <Menu.Item key="/logout" icon={<LogoutOutlined />} onClick={handleLogout}>
                {loading ? <Spin size="small" /> : 'Đăng xuất'} {/* Hiển thị loading nếu đang đăng xuất */}
            </Menu.Item>
        </Menu>
    );
};

export default MenuSidebar;
